import React, {useEffect, useState} from "react";
import {getUserData} from "../utils/cognito-utils";

const UserContext = React.createContext();

const UserProvider = (props) => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)

  useEffect(() => {
    getUserData()
      .then(user => {
        setUser(user);
        setLoading(false)
      })
      .catch(err => {
        setUser(null);
        setLoading(false)
      });
  }, [])

  const getFullName = () => {
    if (user) {
      const payload = user.idToken.payload;
      return `${payload.given_name} ${payload.family_name}`
    } else {
      return ""
    }
  }

  return <UserContext.Provider value={{
    user,
    setUser,
    getFullName,
    loading
  }}>
    <>
      {props.children}
    </>
  </UserContext.Provider>
}

const UserConsumer = UserContext.Consumer

export {UserProvider, UserConsumer, UserContext}
