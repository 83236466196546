import React, {useEffect, useState} from "react";

const ReferrerContext = React.createContext();

const ReferrerProvider = (props) => {
  const [referrer, setReferrer] = useState(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const referrerResult = (new RegExp('[?&]referrer=([^&]*)')).exec(window.location.search)
      if (referrerResult) {
        const newReferrer = decodeURIComponent(referrerResult[1]);
        setReferrer(newReferrer)
        window.sessionStorage.setItem("referrer", newReferrer);
      } else {
        setReferrer(window.sessionStorage.getItem("referrer"))
      }
    }
  }, [])

  return <ReferrerContext.Provider value={{referrer}}>
    {props.children}
  </ReferrerContext.Provider>
}

const ReferrerConsumer = ReferrerContext.Consumer

export {ReferrerProvider, ReferrerConsumer, ReferrerContext}

