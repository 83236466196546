import "./src/css/style.css";
import React from "react";
import {StripeProvider} from 'react-stripe-elements';
// import {Helmet} from "react-helmet/es/Helmet";
import {UserProvider} from "./src/context/UserContext";
import {LikesProvider} from "./src/context/LikesContext";
import {ChatProvider} from "./src/context/ChatContext";
import {ReferrerProvider} from "./src/context/ReferrerContext";

export const onRouteUpdate = ({location}) => {
  // if (process.env.NODE_ENV === `production`) {
  //   window.mixpanel.track(`View page ${location.pathname}`);
  // }
}

export const onClientEntry = (_, pluginParams) => {
  // if (process.env.NODE_ENV === 'production') {
  //   const pluginParams = {dsn: 'https://41e0974aed814d3a90951306d21b4a1a@sentry.io/1482806'}
  //   window.Sentry && window.Sentry.init(pluginParams)
  // }
}

export const wrapRootElement = ({element}) => (
  <UserProvider>
    <LikesProvider>
      <ChatProvider>
        <ReferrerProvider>
          <StripeProvider apiKey={process.env.GATSBY_STRIPE_KEY}>
            <>
              {/*{process.env.NODE_ENV === `production` ?*/}
              {/*  <Helmet>*/}
              {/*  </Helmet> :*/}
              {/*  null}*/}
              {element}
            </>
          </StripeProvider>
        </ReferrerProvider>
      </ChatProvider>
    </LikesProvider>
  </UserProvider>
)

// class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {hasError: false};
//   }
//
//   componentDidCatch(error, info) {
//     if (process.env.NODE_ENV === 'production') {
//       window.Sentry.captureException(error)
//     }
//   }
//
//   render() {
//     return this.props.children;
//   }
// }
