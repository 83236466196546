import {CognitoUserPool} from "amazon-cognito-identity-js";


const userPool = new CognitoUserPool({
  UserPoolId: 'us-east-1_yOcDam9wM',
  ClientId: '4ekbga34tdvljqv3qg5e6648l1',
});

const getUserData = () => new Promise((resolve, reject) => {
  const currentUser = userPool.getCurrentUser();
  if (!currentUser) {
    resolve(null)
  }
  currentUser.getSession((e, d) => {
    if (e) {
      reject(e)
    } else {
      resolve(d)
    }
  })
})

export {
  getUserData,
  userPool
}

