import React, {useContext, useEffect, useState} from "react";
import gql from "graphql-tag";
import {client} from "../apollo/client";
import {UserContext} from "./UserContext";

const LikesContext = React.createContext();

const LIKES_EMPTY = {
  stylist: [],
  blog: [],
  lookbook: [],
  city: [],
  shoppingPackage: []
}

const LIKES_QUERY = gql`
    query Likes {
        likes{
            model
            itemID
        }
    }`


const LikesProvider = (props) => {
  const [likes, setLikes] = useState(LIKES_EMPTY)
  const {user} = useContext(UserContext)

  useEffect(() => {
    if (user) {
      setLikes(LIKES_EMPTY)
      client
        .query({fetchPolicy: 'network-only', query: LIKES_QUERY})
        .then(r => {
          setLikes(r.data.likes.reduce((a, v) => {
            const {model, itemID} = v
            return {...a, [model]: [...a[model], itemID]};
          }, LIKES_EMPTY))
        })
    } else {
      setLikes(LIKES_EMPTY)
    }
  }, [user])


  return <LikesContext.Provider value={{
    likes,
    setLikes
  }}>
    <>
      {props.children}
    </>
  </LikesContext.Provider>
}

const LikesConsumer = LikesContext.Consumer

export {LikesProvider, LikesConsumer, LikesContext}
