// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-continent-js": () => import("../src/templates/continent.js" /* webpackChunkName: "component---src-templates-continent-js" */),
  "component---src-templates-blog-js": () => import("../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-city-js": () => import("../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-partner-js": () => import("../src/templates/partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-shopping-package-js": () => import("../src/templates/shopping-package.js" /* webpackChunkName: "component---src-templates-shopping-package-js" */),
  "component---src-templates-stylist-js": () => import("../src/templates/stylist.js" /* webpackChunkName: "component---src-templates-stylist-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-blogs-js": () => import("../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-booking-js": () => import("../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-booking-confirmation-js": () => import("../src/pages/bookingConfirmation.js" /* webpackChunkName: "component---src-pages-booking-confirmation-js" */),
  "component---src-pages-cities-js": () => import("../src/pages/cities.js" /* webpackChunkName: "component---src-pages-cities-js" */),
  "component---src-pages-contactus-js": () => import("../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-continents-js": () => import("../src/pages/continents.js" /* webpackChunkName: "component---src-pages-continents-js" */),
  "component---src-pages-emailverification-js": () => import("../src/pages/emailverification.js" /* webpackChunkName: "component---src-pages-emailverification-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-lookbook-js": () => import("../src/pages/lookbook.js" /* webpackChunkName: "component---src-pages-lookbook-js" */),
  "component---src-pages-mediakit-js": () => import("../src/pages/mediakit.js" /* webpackChunkName: "component---src-pages-mediakit-js" */),
  "component---src-pages-myaccount-discount-js": () => import("../src/pages/myaccount/discount.js" /* webpackChunkName: "component---src-pages-myaccount-discount-js" */),
  "component---src-pages-myaccount-loginwelcome-js": () => import("../src/pages/myaccount/loginwelcome.js" /* webpackChunkName: "component---src-pages-myaccount-loginwelcome-js" */),
  "component---src-pages-myaccount-membership-js": () => import("../src/pages/myaccount/membership.js" /* webpackChunkName: "component---src-pages-myaccount-membership-js" */),
  "component---src-pages-myaccount-mylookbook-js": () => import("../src/pages/myaccount/mylookbook.js" /* webpackChunkName: "component---src-pages-myaccount-mylookbook-js" */),
  "component---src-pages-myaccount-partnerprofile-js": () => import("../src/pages/myaccount/partnerprofile.js" /* webpackChunkName: "component---src-pages-myaccount-partnerprofile-js" */),
  "component---src-pages-myaccount-stylereport-js": () => import("../src/pages/myaccount/stylereport.js" /* webpackChunkName: "component---src-pages-myaccount-stylereport-js" */),
  "component---src-pages-myaccount-stylistprofile-js": () => import("../src/pages/myaccount/stylistprofile.js" /* webpackChunkName: "component---src-pages-myaccount-stylistprofile-js" */),
  "component---src-pages-myaccount-wishlist-js": () => import("../src/pages/myaccount/wishlist.js" /* webpackChunkName: "component---src-pages-myaccount-wishlist-js" */),
  "component---src-pages-partners-js": () => import("../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-press-js": () => import("../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacypolicy-js": () => import("../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-register-js": () => import("../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-resetpassword-js": () => import("../src/pages/resetpassword.js" /* webpackChunkName: "component---src-pages-resetpassword-js" */),
  "component---src-pages-services-js": () => import("../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-shopping-packages-js": () => import("../src/pages/shopping-packages.js" /* webpackChunkName: "component---src-pages-shopping-packages-js" */),
  "component---src-pages-stylists-js": () => import("../src/pages/stylists.js" /* webpackChunkName: "component---src-pages-stylists-js" */)
}

