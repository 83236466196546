import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {onError} from 'apollo-link-error';
import {ApolloLink} from 'apollo-link';
import {setContext} from 'apollo-link-context';
import fetch from 'isomorphic-fetch';
import {createUploadLink} from "apollo-upload-client";
import {createHttpLink} from "apollo-link-http";
import {getUserData} from "../utils/cognito-utils";

const authLink = setContext(async (_, {headers}) => {
  const session = (await getUserData().catch(() => null));
  return {
    headers: {
      ...headers,
      authorization: session ? `Bearer ${session.idToken.jwtToken}` : "",
    }
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors)
        graphQLErrors.map(({message, locations, path}) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    authLink,
    createUploadLink({
      uri: process.env.GATSBY_API_URL,
      credentials: 'same-origin'
    })
  ]),
  cache: new InMemoryCache(),
  fetch
});

const datoToken = "34196dfa9ade9fba7d833d556a812e"
const datoHttpLink = createHttpLink({uri: "https://graphql.datocms.com/"})
const datoAuthLink = setContext(async (_, {headers}) => {
  return {
    headers: Object.assign(headers || {}, {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: `Bearer ${datoToken}`
    })
  }
});

export const datoClient = new ApolloClient({
  link: datoAuthLink.concat(datoHttpLink),
  cache: new InMemoryCache()
})