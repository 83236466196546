module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LookStyler","short_name":"LookStyler ","start_url":"/","background_color":"#0C0F12","theme_color":"#000","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-104556551-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"fe235fffa6a6b580a0c1fc5851622fc7","pageViews":"all"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WQVP536","includeInDevelopment":true},
    },{
      plugin: require('../node_modules/@loicmahieu/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://41e0974aed814d3a90951306d21b4a1a@sentry.io/1482806"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
