import React, {useCallback, useContext, useEffect, useReducer, useRef, useState} from "react";
import {client as apolloClient} from "../apollo/client";
import {UserContext} from "./UserContext";
import gql from "graphql-tag";
import Client from 'twilio-chat';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft, faBell, faTimesCircle} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import {connect, createLocalVideoTrack} from "twilio-video";
import calculateVideoWindow from "../utils/window-utils";

const ChatContext = React.createContext();

const channelsReducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return {}
    case 'insert':
      const channel = action.channel
      return {...state, [channel.sid]: {channel}}
    case 'setCount': {
      const sid = action.channel.sid;
      return {...state, [sid]: {...state[sid], count: action.count}}
    }
    case 'setMembers': {
      const sid = action.channel.sid;
      return {...state, [sid]: {...state[sid], members: action.members}}
    }
    case 'addMember': {
      const sid = action.channel.sid;
      const channel = state[sid];
      return {...state, [sid]: {...channel, members: [...channel.members, action.member]}}
    }
    default:
      throw new Error();
  }
}

const ChatProvider = (props) => {
  const {user} = useContext(UserContext)
  const [client, setClient] = useState(null)
  const [channels, dispatchChannels] = useReducer(channelsReducer, {})
  const [channel, _setChannel] = useState(null)
  const [messages, setMessages] = useState([])
  const [open, setOpen] = useState(false)
  const [sid, setSid] = useState(null)
  const [videoToken, setVideoToken] = useState(null)
  const [screen, setScreen] = useState('channels')
  const refContainer = useRef(null)
  const [room, setRoom] = useState(null)
  const [chatError, setChatError] = useState(null)
  const meMediaEl = useRef(null);
  const otherMediaEl = useRef(null);
  const [windowDimensions, setWindowDimensions] = useState({width: 0, height: 0})
  const [videoDimensions, setVideoDimensions] = useState({width: 640, height: 480})

  const fetchToken = () =>
    apolloClient
      .query({
        fetchPolicy: 'network-only',
        query: GET_TEXT_CHAT_TOKEN
      })
      .then(({data: {getTextChatToken: token}}) => token)

  const updateMessageCount = channel =>
    Promise
      .all([
        channel.getMessagesCount(),
        channel.getUnconsumedMessagesCount(),
      ])
      .then(([
               messagesCount,
               unconsumedMessagesCount,
             ]) => {
        const count = unconsumedMessagesCount === null ? messagesCount : unconsumedMessagesCount
        dispatchChannels({type: 'setCount', channel, count})
      })

  useEffect(() => {
    if (user) {
      fetchToken().then(token => {
        Client
          .create(token)
          .then(c => {
            setClient(c)
            c.on('tokenAboutToExpire', () => fetchToken().then(t => c.updateToken(t)));
            c.on('channelAdded', channel => {
              dispatchChannels({type: 'insert', channel})

              channel
                .getMembers()
                .then((members) => {
                  Promise
                    .all(
                      members
                        .filter(m => m.identity !== c.user.identity)
                        .map(member => member.getUser().then(user => ({user, member})))
                    )
                    .then(members => dispatchChannels({type: 'setMembers', channel, members}))
                })
              updateMessageCount(channel)
              channel.on('updated', ({channel}) => updateMessageCount(channel))
              channel.on('memberJoined', member => member
                .getUser()
                .then(user => ({
                  user,
                  member
                }))
                .then(member => dispatchChannels({type: 'addMember', channel, member}))
              )
            })
          })
          .catch(e => {
            console.error(e);
          })
      })
    }
  }, [user])

  useEffect(() => {
    if (!user && client) {
      client.shutdown()
      setClient(null)
      _setChannel(null)
      setMessages(null)
      dispatchChannels({type: 'clear'})
      setOpen(false)
    }
  }, [user, client])

  const unreadMessages = Object.values(channels)
    .map(c => c.count)
    .reduce((sum, x) => sum + x, 0)

  const Closed = () => <div
    className='rounded-t-lg py-2 pl-2 text-white bg-gray-900 flex justify-between cursor-pointer'
    style={{zIndex: 2000}}
    onClick={() => setOpen(o => !o)}>
    <span className="">Chat</span>
    {/*<button*/}
    {/*  className='font-sans font-light bg-yellow-700 text-black p-1 rounded'*/}
    {/*  onClick={(e) => {*/}
    {/*    startSupportTextChat()*/}
    {/*    e.preventDefault()*/}
    {/*    e.stopPropagation()*/}
    {/*  }}*/}
    {/*>Talk with LookStyler*/}
    {/*</button>*/}
    {
      unreadMessages > 0 ?
        <div className='mx-4 text-green-500 rounded-t-lg'>
          <FontAwesomeIcon icon={faBell} spin={true} size="1x"/>
          <span className={'mx-2'}>unread: {unreadMessages}</span>
        </div> :
        <div/>
    }
  </div>

  const OpenHeader = () => <div className="flex justify-between text-black bg-yellow-700 p-2 rounded-t-lg">
    <FontAwesomeIcon icon={faArrowAltCircleLeft} size="2x" onClick={() => setScreen('channels')}
                     className={'cursor-pointer sm:invisible' + (screen === 'channels' ? ' invisible' : '')}/>
    <FontAwesomeIcon icon={faTimesCircle} size="2x" onClick={() => setOpen(o => !o)} className='cursor-pointer'/>
  </div>

  useEffect(() => {
    if (open && refContainer.current) {
      refContainer.current.scrollTop = refContainer.current.scrollHeight
    }
  }, [messages, open])

  useEffect(() => {
    if (open && channel && messages.length > 0) {
      channel.channel.updateLastConsumedMessageIndex(messages.slice(-1)[0].index)
    }
  }, [channel, messages, open])


  useEffect(() => {
    if (channel) {
      channel
        .channel
        .getMessages()
        .then(({items: messages}) => {
          setMessages(messages)
        })
    }
  }, [channel])

  useEffect(() => {
    if (channel) {
      dispatchChannels({type: 'setCount', channel: channel.channel, count: 0})
    }
  }, [channel])


  const changeChannel = useCallback(
    (newChannel) => {
      setMessages([])
      _setChannel(channel => {
          if (channel) {
            channel.channel.removeAllListeners('messageAdded')
            channel.channel.removeAllListeners('updated')
            channel.channel.on('updated', ({channel}) => updateMessageCount(channel))
          }
          newChannel.channel.removeAllListeners('updated')
          newChannel.channel.on('messageAdded', m => {
            setMessages(messages => [...messages, m])
          })
          return newChannel
        }
      );
    },
    [],
  );

  useEffect(() => {
    if (sid && channels[sid]) {
      changeChannel(channels[sid])
      setSid(null)
    }
  }, [sid, channels, changeChannel])

  const Channels = ({channels}) => {
    return <div>{
      Object
        .values(channels)
        .map(v => {
          const member = client && v.members && v.members.find(m => m.user.identity !== 'LookStyler' && m.user.identity !== client.user.identity)
          // (v.members || []).map(e => e.member.identity).forEach(d => console.log(d))
          return <div key={v.channel.sid}
                      className={'rounded m-2 bg-gray-300 px-2 py-1 flex justify-between border-2' + (channel && v.channel.sid === channel.channel.sid ? ' border-black' : '')}
                      onClick={() => {
                        setScreen('channel')
                        changeChannel(v);
                      }}>
            <span>{member && member.user.friendlyName}</span>
            {v.count && v.count !== 0 ?
              <span className='bg-black text-white px-2 py-1 rounded-full text-xs'>{v.count}</span> :
              <span/>}
          </div>;
        })
    }
    </div>;
  }

  const Channel = ({messages}) => {
    return channel ?
      <div className='flex-1 my-1 overflow-y-auto h-full' ref={refContainer}>{messages.map(m => <Message
        key={m.sid}
        m={m}
        members={channel.members}
        identity={client.user.identity}/>)}</div> :
      null;
  }
  const videoChatRequested = async room => {
    const {data: {getVideoChatToken: videoToken}} = await apolloClient.query({
      fetchPolicy: 'network-only',
      query: GET_VIDEO_CHAT_TOKEN,
      variables: {input: {room}}
    })
    setVideoToken(videoToken)
  }

  const Message = ({m, identity, members}) => {
    const author = members && m.author !== identity && members.find(u => u.user.identity === m.author)
    return <div key={m.sid}
                className={'px-2 flex flex-col ' + (m.author === identity ? ' items-end ' : ' items-start')}>
      <div className="flex">
        <span className='pr-2 py-1 my-1 text-xs'>{author && author.user.friendlyName}</span>
        <span className='rounded bg-gray-300 px-2 py-1 my-1'>{
          Object.keys(Object.keys(m.attributes)).length > 0 ?
            (
              m.author === identity ?
                <div className='italic'>Video chat requested</div> :
                <div>
                  <span>Do you want to start a video chat?</span>
                  <button className='bg-green-300 rounded py-1 px-2 ml-2'
                          onClick={() => videoChatRequested(m.attributes.room)}>Start
                  </button>
                </div>
            )
            : m.body
        }</span>
      </div>
      <span className='text-gray-500 text-xs'>{moment(m.timestamp).format('D MMM, HH:mm')}</span>
    </div>;
  }

  const AddMessage = () => {
    const [message, setMessage] = useState('')
    return <form onSubmit={e => {
      e.preventDefault();
      channel.channel.sendMessage(message)
      setMessage('')
    }}>
      <input
        type="text"
        className='p-3 focus:outline-none font-light w-full border-t'
        placeholder='Type here and press enter...'
        autoFocus={true}
        value={message}
        onChange={v => setMessage(v.target.value)}
      />
    </form>;
  }

  const startTextChat = async i => {
    const {data: {createTextChannel: r}} = await apolloClient.mutate({
      mutation: CREATE_TEXT_CHANNEL,
      variables: {input: {identity: i, fromIdentity: client.user.identity}}
    });
    if (!r) {
      return false
    }
    setSid(r.sid)
    setOpen(true)
    return true
  }

  const startVideoChat = async i => {
    if (!videoToken) {
      const {data: {createVideoChatRequest: r}} = await apolloClient.mutate({
        mutation: CREATE_VIDEO_CHAT_REQUEST,
        variables: {input: {identity: i, fromIdentity: client.user.identity}}
      })
      if (!r) {
        return false
      }
      const {sid, videoToken} = r
      setSid(sid)
      setVideoToken(videoToken)
    }
    setOpen(true)
    return true
  }

  useEffect(() => {
    const handleResize = () => setWindowDimensions({width: window.innerWidth, height: window.innerHeight});
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const stopSession = useCallback(
    () => {
      if (room) {
        room.disconnect()
        setRoom(null)
      }
      removeChildren(meMediaEl)
      removeChildren(otherMediaEl)
      setVideoToken(null)
    },
    [room],
  );

  const removeChildren = el => {
    while (el.current.firstChild) {
      el.current.removeChild(el.current.firstChild);
    }
  }
  const handleParticipant = participant => {
    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        otherMediaEl.current.appendChild(publication.track.attach());
      }
      publication.on('subscribed', track => {
        if (track.kind === 'video') {
          track.once('started', () => {
            return setVideoDimensions(track.dimensions);
          });
        }
      });
    });

    participant.on('trackSubscribed', track => {
      otherMediaEl.current.appendChild(track.attach());
    });
  };

  useEffect(() => {
    setChatError(null)
    if (videoToken) {
      connect(videoToken, {audio: true, video: {}})
        .then(
          room => {
            room.on('disconnected', (room) => {
              room.localParticipant.tracks.forEach(publication => {
                const attachedElements = publication.track.detach();
                attachedElements.forEach(element => element.remove());
              });
              return setRoom(null);
            })
            setRoom(room)
            createLocalVideoTrack({width: 300})
              .then(track => {
                meMediaEl.current.appendChild(track.attach());
              });

            room.participants.forEach(handleParticipant);
            room.once('participantConnected', handleParticipant)
            room.on('participantDisconnected', () => {
              room.disconnect()
              setRoom(null)
              removeChildren(meMediaEl)
              removeChildren(otherMediaEl)
              setVideoToken(null)
            })
          },
          error => {
            console.error(`Unable to connect to Room: ${error.message}`);
            setChatError(`Unable to connect to Chat Room: ${error.message}`)
          })
    }
  }, [videoToken])

  // console.log(videoDimensions, windowDimensions)

  return <ChatContext.Provider value={{startTextChat, startVideoChat, ready: client !== null}}>
    {props.children}
    {user && <div
      className={"flex flex-col fixed bottom-0 right-0 font-sans font-light " + (open ? ' h-screen lg:h-200 w-full' : 'w-full lg:w-1/4')}
      style={{zIndex: open ? 2000 : 1000}}
    >
      <div
        className={'rounded-t-lg h-full border bg-white flex full flex-col' + (open ? '' : ' hidden')}
        style={{zIndex: open ? 2000 : 100}}
      >
        <OpenHeader/>
        <div className={"w-full flex my-1 flex-col items-center" + (videoToken ? '' : ' hidden')}>
          {chatError && <div className="my-2 font-sans font-light text-xl text-red-700">{chatError}</div>}
          <div className="bg-gray-300 relative border border w-full"
               style={calculateVideoWindow(videoDimensions.width, videoDimensions.height, windowDimensions.width, windowDimensions.height)}
          >
            <div ref={otherMediaEl} className='z-10 absolute inset-0 video'/>
            <div ref={meMediaEl} className='z-40 video absolute w-1/4 right-0 bottom-0'/>
          </div>
          {room && <button
            onClick={() => stopSession(room)}
            className='text-lg tracking-wide font-sans bg-yellow-700 hover:bg-black text-white font-bold my-2 py-2 px-8 focus:outline-none focus:shadow-outline uppercase'>Stop
            chat</button>}
        </div>
        <div className={"h-full flex-1 flex" + (videoToken ? ' hidden' : '')}>
          <div className={"w-full lg:w-2/5 border-r h-full" + (screen === 'channel' ? ' hidden sm:block' : '')}>
            <Channels channels={channels}/>
          </div>
          <div className={"w-full lg:w-3/5 flex flex-col h-full" + (screen === 'channels' ? ' hidden sm:block' : '')}>
            <Channel messages={messages}/>
            {channel && <AddMessage/>}
          </div>
        </div>
      </div>
      <Closed/>
    </div>
    }
  </ChatContext.Provider>
}

const ChatConsumer = ChatContext.Consumer

export {ChatProvider, ChatConsumer, ChatContext}

const GET_TEXT_CHAT_TOKEN = gql`
    query GetTextChatToken {
        getTextChatToken
    }`

const CREATE_TEXT_CHANNEL = gql`
    mutation CreateTextChannel($input: CreateTextChannelInput!) {
        createTextChannel(input: $input){
            sid
            identity
            token
        }
    }`

const CREATE_VIDEO_CHAT_REQUEST = gql`mutation CreateVideoChatRequest($input: CreateVideoChatRequestInput!) {
    createVideoChatRequest(input: $input){
        sid
        identity
        token
        videoToken
    }
}
`

const GET_VIDEO_CHAT_TOKEN = gql`
    query GetVideoChatToken($input: VideoChatTokenInput!) {
        getVideoChatToken(input: $input)
    }
`



