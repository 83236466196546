const calculateVideoWindow = (videoWidth, videoHeight, windowWidth, windowHeight) => {
  if (windowWidth < videoWidth) {
    if (windowWidth * videoHeight / videoWidth > windowHeight) {
      return {
        width: windowHeight * videoWidth / videoHeight,
        height: windowHeight
      }
    } else {
      return {
        width: windowWidth,
        height: windowWidth * videoHeight / videoWidth
      }
    }
  } else if (windowHeight < windowWidth) {
    return {
      width: windowHeight * videoWidth / videoHeight,
      height: windowHeight
    }
  } else {
    return {
      width: videoWidth,
      height: videoHeight
    }
  }
}

export default calculateVideoWindow


